import { Text } from "grommet";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import isYesterday from "date-fns/isYesterday";
import { isAfter } from "date-fns";
import { useCoin } from "@organisms/coin/provider";
import utc0 from "@helpers/utc0";

const getDate = (value) => {
  const date = utc0(value);

  if (isToday(date)) return "today";
  if (isTomorrow(date)) return "in 1 day";
  if (isYesterday(date)) return "1 day ago";

  const distance = formatDistanceStrict(date, utc0(new Date()));

  if (isAfter(date, utc0(new Date()))) return `in ${distance}`;

  return distance;
};

export default function Launched({ slug }) {
  const { deployedAt } = useCoin(slug);

  if (!deployedAt)
    return (
      <Text size="small" color="dark-4">
        -
      </Text>
    );

  return <Text size="small">{getDate(deployedAt)}</Text>;
}
