import { Box, Text } from "grommet";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import WatchlistButton from "@organisms/watchlist/button";

export default function Watchlist({ slug, rank, index }) {
  const large = useIsLarge();
  return (
    <Box
      direction={large ? "row" : "column"}
      align="center"
      justify={rank ? "between" : "center"}
    >
      {rank && (
        <Text size="xsmall" color="dark-4">
          {index}
        </Text>
      )}
      <WatchlistButton slug={slug} short />
    </Box>
  );
}
// import Popover from "components/popover";
// import PortfolioButton from "@organisms/portfolio/button";

// function PortfolioPopover({ slug, ...rest }) {
//   return (
//     <Box pad={{ left: "small" }} {...rest}>
//       <Box
//         background="#26272C"
//         pad="small"
//         border={true}
//         round="xsmall"
//         align="start"
//         gap="xsmall"
//       >
//         <WatchlistButton slug={slug} />
//         <PortfolioButton slug={slug} />
//       </Box>
//     </Box>
//   );
// }
// return (
//   <Popover
//     dropProps={{
//       align: { left: "right" },
//       elevation: "none",
//       background: "inherit",
//     }}
//     popover={(props) => <PortfolioPopover slug={slug} {...props} />}
//   >
//     {() => <WatchlistButton slug={slug} short />}
//   </Popover>
// );
